<template>
  <div class="Demo-DOH">
    <header
      class="bg-clr sticky-top flex-d c-header c-header-light c-header-fixed c-header-with-subheader"
    >
      <div>
        <div class="col-12 logo">
          <div>
            <a
              href="https://www.doh.gov.ae/covid-19/Health-Workforce-Management-System"
              target="_blank"
              class="p-1 d-inline-block"
              ><img src="/img/doh.png" class="mw-100 h-auto"
            /></a>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column logo-title p-2 linespcad title">
        <div>
          <span class="ml-1"><small>Abu</small> </span>
          <span class="ml-1"> <small>Dhabi's</small> </span>
          <span class="ml-1"> <small>Health</small> </span>
          <span class="ml-1"> <small>Workforce</small> </span>
          <span class="ml-1">
            <small>Management</small>
          </span>
          <span class="ml-1">
            <small>System</small>
          </span>
        </div>
      </div>
    </header>
    <div style="margin: 70px 0px; padding-top: 2rem" class="container-fluid">
      <JobDetail />
    </div>
    <footer
      class="page-footer f-bg font-small white text-white c-footer c-footer-fixed"
    >
      <div>
        <div class="ml-1 copy-right">
          © 2011 – 2020 OHR Ltd or its affiliates
        </div>
      </div>
      <div class="ml-auto d-flex flex-column">
        <span class="mr-1"
          ><span class="fw-b"
            ><div class="logo-adjustment">powered by</div>
            TALENT</span
          ><span class="fw-5">FIND™</span></span
        >
      </div>
    </footer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import JobDetail from "@/pages/DemoDOH/JobBoardDetail/jobDetailMobile.vue";
export default {
  components: {
    JobDetail,
  },
  data() {
    return {
      urlFullPath: "",
    };
  },
  created() {
    const {
      fullPath,
      params: {uuid },
    } = this.$route;
    this.urlFullPath = fullPath;
    this.jobBoardDetailAction({ job_uuid: uuid, path: this.urlFullPath });
  },
  methods: {
    ...mapActions(["jobBoardDetailAction"]),
  },
};
</script>
<style lang="scss" scoped>
.Demo-DOH {
  .c-header.c-header-fixed {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .c-header[class*="bg-"] {
    border-color: rgba(0, 0, 21, 0.1);
  }
  .bg-clr {
    background: #0A5E67;
  }
  .container-fluid {
    padding-right: 30px;
    padding-left: 30px;
  }
}
</style>