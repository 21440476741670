<template>
  <div class="job-board">
    <CModal
      id="applyjobModal"
      title="Apply Job"
      color="primary"
      centered:false
      @update:show="onShow"
      :show.sync="successModal"
      >{{ modalMessage }}</CModal
    >
    <div class="job-board-detail-component container-fluid">
      <div>
        <CRow class="d-flex justify-content-center">
          <img
            v-if="fileSource.logo"
            :src="fileSource.logo"
            class="rounded-circle w-100 h-100"
            style="width: 100px; height: 100px"
          />
          <img
            v-else
            src="/img/hospital.png"
            class="rounded-circle"
            style="width: 100px; height: 100px"
          />
        </CRow>
      </div>
      <div class="title">
        <p>{{ organisation }}</p>
        <h5 class="label lblspan text-primary text-center">
          Location:
          <span style="color: #00000099">{{ location }} </span>
        </h5>
      </div>
      <div class="title mt-5">
        <h5 class="text-center">
          {{ title }}
        </h5>
      </div>
      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Candidate Type</div>
          <div class="value text-left">{{ candidateType }}</div>
        </div>
        <div class="detail-group-item">
          <div class="label">Candidate Level</div>
          <div class="value">{{ candidateLevel }}</div>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Job Reference</div>
          <div class="value text-left">{{ jobReference }}</div>
        </div>
        <div class="detail-group-item">
          <div class="label">Contract</div>
          <div class="value">{{ contract }}</div>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="detail-group">
        <div class="detail-group-item">
          <div class="label">Date created</div>
          <div class="value text-left">{{ date_created }}</div>
        </div>
        <div class="detail-group-item">
          <div class="label">Expiry Date</div>
          <div class="value">{{ expiryDate }}</div>
        </div>
      </div>

      <div class="border-line-dashed d-md-none d-lg-none"></div>

      <div class="action-group">
        <button
          v-if="!getSelectedJobBoard.status"
          type="button"
          class="btn btn-primary btn-block mb-3"
          @click="onApplyJob()"
        >
          Apply Now
        </button>
        <p
          v-if="getSelectedJobBoard.status && getSelectedJobBoard.status.status"
          class="text-center my-3 status"
        >
          {{ getSelectedJobBoard.status.status }}
        </p>
        <social-sharing
          :url="url"
          :title="title"
          :description="jobReference"
          inline-template
        >
          <CDropdown class="nav-dropdown px-4" add-menu-classes="pt-0">
            <template #toggler-content>
              <div class="d-flex align-items-center text">
                <CIcon name="cil-share-alt" class="color-primary" />
              </div>
            </template>
            <CDropdownItem>
              <network network="email">
                <i class="fa fa-envelope"></i> Email
              </network>
            </CDropdownItem>
            <div>
              <CDropdownItem>
                <network network="facebook">
                  <i class="fa fa-facebook"></i> Facebook
                </network>
              </CDropdownItem>
            </div>
          </CDropdown>
        </social-sharing>
      </div>
      <hr />
      <div class="text-center">
        <img
          v-if="fileSource.image"
          :src="fileSource.image"
          style="width: 80%; height: 80%"
        />
        <img
          v-else
          src="/img/job-image.jpg"
          style="width: 80%; height: 80%"
        />
      </div>
      <hr />
      <div class="job-description-content" v-html="jobDescription"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import jobDetail from "./jobDetail.vue";
export default {
  extends: jobDetail,
};
</script>
<style lang="scss" scoped>
.lblspan {
  font-size: 16px !important;
  font-weight: 300 !important;
}
.title > p {
  color: #085D66E6 !important;
}
.text-primary {
  color: #085D66E6 !important;
}
.btn-primary {
  background-color: #085D66E6 !important;
  border-color: #085D66E6 !important;
}
</style>
